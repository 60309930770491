<template>
  <div class="d-flex flex-row flex-wrap login">
    <div class="d-flex justify-center align-center login__form-wrapper">
      <div class="text-center welcome">
        <img src="@/assets/images/logo.svg" alt="login">
        <div class="welcome__title">Restablece tu Contraseña</div>
        <div class="welcome__description mb-6">
          Por favor ingresa tu nueva contraseña
        </div>
        <validation-observer ref="form">
          <form novalidate autocomplete="off" @submit.prevent="passwordResetConfirm">
            <validation-provider
              v-slot="{ errors }"
              name="Nueva Contraseña"
              rules="required|verifyPassword"
            >
              <v-text-field
                vid="password"
                v-model="model.password"
                :type="showPassword ? 'text' : 'password'"
                outlined
                dense
                :error-messages="errors"
                label="Nueva Contraseña"
                required
              >
                <v-icon slot="append" @click="showPassword = !showPassword">
                  {{ showPassword ? 'mdi-eye-off' : 'mdi-eye'}}
                </v-icon>
              </v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="Confirmar Contraseña"
              rules="required|confirmed:Nueva Contraseña|verifyPassword"
            >
              <v-text-field
                vid="password_confirmation"
                v-model="model.password_confirmation"
                :type="showPassword ? 'text' : 'password'"
                outlined
                dense
                :error-messages="errors"
                label="Confirmar Contraseña"
                required
              >
                <v-icon slot="append" @click="showPassword = !showPassword">
                  {{ showPassword ? 'mdi-eye-off' : 'mdi-eye'}}
                </v-icon>
              </v-text-field>
            </validation-provider>
            <v-btn color="grey darken-4" type="submit" class="white--text mb-5" block>
              Cambiar Contraseña
            </v-btn>
          </form>
        </validation-observer>
      </div>
    </div>
    <div class="login__banner">
    </div>
  </div>
</template>
<script>
import routerMixin from '@/mixins/routerMixin'
import sessionMixin from '@/mixins/sessionMixin'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import notificationMixin from '@/mixins/notificationMixin'
import loaderMixin from '@/mixins/loaderMixin'
import { PASSWORD_CHANGE_URL } from '@/constants/ServicesConstants'

export default {
  name: 'PasswordResetConfirm',
  beforeRouteEnter (to, from, next) {
    const user = sessionMixin.methods.getUser()
    if (sessionMixin.methods.isLoggedIn() && user.resetPassword !== 1) {
      const page = routerMixin.methods.goTo(sessionMixin.methods)
      next(page)
    } else {
      next()
    }
  },
  data: () => ({
    model: {
      username: '',
      password: '',
      password_confirmation: ''
    },
    showPassword: false
  }),
  mixins: [
    sessionMixin,
    crudServiceMixin,
    notificationMixin,
    loaderMixin
  ],
  methods: {
    passwordResetConfirm () {
      this.$refs.form.validate()
        .then((valid) => {
          if (valid) {
            this.showLoader()
            this.model.username = this.getUser().username
            this.post(PASSWORD_CHANGE_URL, this.model)
              .then(() => {
                const userInfo = JSON.parse(localStorage.getItem('userInfo'))
                userInfo.user.reset_password = 0
                localStorage.setItem('userInfo', JSON.stringify(userInfo))
                this.$router.replace('/expositores/reserva-charlas')
                this.showSuccess('Se ha cambiado la contraseña correctamente', 3000)
                this.hideLoader()
              }).catch((error) => {
                if (error.response.data && error.response.status === 422) {
                  for (const key in error.response.data) {
                    this.$refs.form.errors['Nueva Contraseña'].push(error.response.data[key][0])
                    this.$refs.form.errors['Confirmar Contraseña'].push(error.response.data[key][0])
                  }
                } else {
                  this.showError(error.response.data.error || 'Ha ocurrido un error.', 3000)
                }
                this.hideLoader()
              })
          }
        })
    }
  }
}
</script>

<style lang="scss">
.login {
  height: 100vh;

  &__form-wrapper {
    width: 100%;
  }

  &__banner {
    background: url("../../../assets/images/login-expositor-banner.jpg") no-repeat center;
    background-size: cover;
    display: none;
    width: 55%;
  }
}

@media (min-width: 990px) {
  .login {
    &__form-wrapper {
      width: 45%;
    }

    &__banner {
      display: flex;
    }
  }
}

.welcome {
  width: 350px;

  img {
    width: 150px;
  }

  &__title {
    font-size: 30px;
    font-family: 'Roboto Bold', sans-serif !important;
  }

  &__description {
    color: #808080;
    line-height: 10px
  }

}

</style>
